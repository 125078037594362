.formForgetText {
  color: #b7b8b9;
  text-decoration: underline;
  cursor: pointer;
  display: block;
  text-align: right;

  &:hover {
    color: #2d2d2d;
  }
}
