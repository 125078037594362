.protocal {

  h2 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  dl {
    margin-top: 25px;

    dl {
      margin-top: 10px;
    }

    dt {
      font-size: 16px;
      margin-bottom: 10px;
    }

    dd {
      margin-bottom: 5px;
      text-indent: 2em;
      line-height: 1.6;
    }

    span {
      font-weight: bold;
    }
  }

  p {
    margin-bottom: 4px;
    text-indent: 2em;
  }
}
