.feedback-modal {
  position: fixed;
  width: 450px;
  height: 340px;
  top: 10%;
  left: 50%;
  transform: translateX(-225px);
  border-radius: 10px;
  z-index: 999;
}

.feedback-modal-hidden {
  display: none;
}

.light-style-dark {
  background-color: #202123;
}

.light-style-dark .feedback-input {
  background-color: #444653;
  color: #f9fafb;
}

.light-style-light {
  background-color: #dfe1e5;
  color: #202123;
}

.light-style-light .feedback-input {
  background-color: #ffffff;
  color: #202123;
}

.light-style-light .feedback-submit {
  color: #dfe1e5;
}

.feedback-title {
  font-size: 20px;
  padding: 10px 5px;
  margin: 5px;
  border-bottom: 1px solid #fff;
}

.feedback-title svg {
  display: inline-block;
  padding-right: 5px;
}

.feedback-content {
  margin-left: 20px;
  margin-top: 20px;
}

input[type='checkbox']::after {
  background-color: #444653;
}

.feedback-submit {
  background-color: #444653;
  padding: 5px 10px;
  border-radius: 5px;
  float: right;
  margin: 5px 20px;
}

.close-btn {
  position: absolute;
  right: 10px;
  top: 15px;
}

.feedback-input {
  padding: 5px;
}

.smegma-content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
  z-index: 998;
}

.smegma-content-hidden {
  display: none;
}

button.ant-btn-primary,
.ant-btn-primary[type='button'] {
  background: #1677ff;
}

button:global(.ant-btn-default) {
  background: #ffffff;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.88);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
}

button.ant-btn-primary:disabled {
  border-color: #d9d9d9;
  background: #f5f5f5;
  color: #00000040;
  width: 60px;
}
