.login {
  width: 100vw;
  height: 100vh;
  background: url('https://pic.imgdb.cn/item/649e817b1ddac507cc36bac9.jpg') no-repeat;
  background-size: 100% 100%;
  padding-top: 100px;
}

.login-header {
  position: relative;
  width: 600px;
  margin: 0 auto;
}

.login-header img {
  position: absolute;
  left: 220px;
  bottom: 13px;
  width: 35px;
}

.login-title {
  display: block;
  width: 600px;
  padding-left: 55px;
  padding-bottom: 10px;
  margin: 0 auto;
  margin-bottom: 20px;
  text-align: center;
  font-size: 25px;
}

.login-form {
  position: relative;
  width: 650px;
  height: 450px;
  /* background-color: rgba(255, 255, 255, 0.5); */
  /* border-radius: 10px; */
  /* box-shadow: 10px 5px 10px 0 #080808; */
  margin: 0 auto;
  /* padding-top: 20px; */
}

.login-form-title {
  width: 150px;
  padding-bottom: 5px;
  border-bottom: 2px solid #000;
  margin: 0 auto;
  margin-bottom: 10px;
  text-align: center;
  font-size: 18px;
  color: #000;
}

.login-form .login-username {
  margin-top: 30px;
  margin-bottom: 50px;
}

.login-form .login-password {
  padding-right: 30px;
}

.login-form input {
  display: block;
  width: 420px;
  height: 45px;
  padding: 5px;
  padding-left: 40px;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  margin: 0 auto;
  color: #888;
}

.login-form input:focus-visible {
  outline-style: none;
  box-shadow: 5px 5px 5px 0 #666;
  background-color: #fff;
}

.username-content {
  position: relative;
  height: 125px;
}

.password-content {
  position: relative;
  height: 45px;
}

.username-icon {
  position: absolute;
  top: 12px;
  left: 120px;
  color: #888;
}

.password-icon {
  position: absolute;
  top: 12px;
  left: 120px;
  color: #888;
}

.username-icon,
.password-icon {
  padding-right: 5px;
  border-right: 1px solid #888;
}

.login-button {
  display: block;
  width: 420px;
  margin: 0 auto;
  margin-top: 60px;
  padding: 10px;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  text-align: center;
  background-color: #ffffff;
  font-size: 20px;
}

.login-button:hover {
  background-color: #ececec;
}

.username-warning {
  position: absolute;
  left: 115px;
  top: 52px;
  color: red;
  font-size: 14px;
}

.password-warning {
  position: absolute;
  left: 115px;
  top: 52px;
  color: red;
  font-size: 14px;
}

.password-show-icon {
  display: none;
  position: absolute;
  right: 122px;
  top: 12px;
  color: #888;
  cursor: pointer;
}

.login-password:hover + .password-show-icon,
.login-password:active + .password-show-icon {
  display: block;
}

.password-show-icon:hover,
.password-show-icon:active {
  display: block;
}
