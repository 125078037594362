/* Guard加载时的界面样式*/
.g2-init-setting-loading {
  min-height: 284px;
  width: 100%;
}

.g2-view-container {
  min-height: 284px;
  width: 100%;
  padding: 6px 6px 22px 6px;
  background-color: #fbfbfb;
  border-radius: 2px
}

#authing-guard-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px 0;
  background: url(https://files.authing.co/user-contents/photos/d8bbb209-87b8-4e50-8bb5-16f6c59286e6.jpeg) left top;
  background-size: cover;
}

.loginContent {
  width: 324px;
  padding: 24px;
  box-sizing: content-box;
  background: #fff;
  border-radius: 8px;
}


.loginContent button.ant-btn-primary,
.ant-btn-primary[type='button'] {
  background: #1677ff;
}

// .loginContent button.ant-btn-primary:disabled {
//   color: #fff;
// }

.loginContent :global(.ant-btn-default) {
  background: #ffffff;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.88);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
}


.loginContent button.ant-btn-primary:disabled {
  border-color: #d9d9d9;
  background: #f5f5f5;
  color: #00000040;
  width: 60px;
}