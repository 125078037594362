.formContent {
  padding: 0px 16px;
  box-sizing: border-box;
}

.tabs {
  display: flex;
  justify-content: space-between;
  padding: 4px 26px;
}

.tabItems {
  font-size: 16px;
  padding: 15px 0px;
  cursor: pointer;
  color: #7a7b7b;

  &:hover {
    color: #2d2d2d;
  }
}

.tabItemsActive {
  color: #2d2d2d;
}

.codeBtton {
  margin-left: 8px;
}

.fullWidth {
  width: 100%;
}

.divider {
  margin: 0px !important;
}

.footer {
  display: flex;
  justify-content: center;
  padding: 8px 60px;
  align-items: center;
}

.formItem {
  display: flex;
  justify-content: flex-end;
}

.formForget {
  color: #b7b8b9;
  cursor: pointer;

  &:hover {
    color: #2d2d2d;
  }
}

.footerText a {
  text-align: right;
  color: #b7b8b9;
  margin-right: -24px;
  display: block;

  &:hover {
    color: #2d2d2d;
  }
}

.shaking {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes shake {
  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-3px, 0, 0);
    transform: translate3d(-3px, 0, 0);
  }

  40%,
  60% {
    -webkit-transform: translate3d(3px, 0, 0);
    transform: translate3d(3px, 0, 0);
  }
}

.confirmTitle {
  margin-top: 0px;
}
