@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

::-webkit-scrollbar-track:hover {
  background-color: #f2f2f2;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

html {
  background: #fff;
  /* background: #202123; */
}

@media (max-width: 720px) {
  pre {
    width: calc(100vw - 110px);
  }
}

pre:has(div.codeblock) {
  padding: 0;
}

.user-info {
  position: absolute;
  right: 12px;
  bottom: 14px;
  font-size: 16px;
}

.user-info img {
  display: inline-block;
  width: 25px;
  margin-right: 10px;
  border-radius: 50%;
}

.log-out {
  position: absolute;
  bottom: 60px;
  right: 7px;
  padding: 10px;
  border-radius: 4px;
  background-color: #343540;
}

.log-out .log-out-icon {
  display: inline-block;
}

.log-out .triangle-icon {
  position: absolute;
  left: 33px;
  color: #343540;
}
