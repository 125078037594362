.promptImg {
  vertical-align: middle;
  margin-top: -2px;

  img {
    vertical-align: middle;
    width: 14px;
    height: 14px;
  }
}
